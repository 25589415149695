export class Embed {

  constructor(
    public id?: number,
    public serverId?: string,
    public serverName?: string,
    public channelId?: string,
    public channelName?: string,
    public title?: string,
    public color?: string,
    public description?: string,
    public field1a?: string,
    public field1b?: string,
    public field1space?: boolean,
    public field1blank?: boolean,
    public field2a?: string,
    public field2b?: string,
    public field2space?: boolean,
    public field2blank?: boolean,
    public field3a?: string,
    public field3b?: string,
    public field3space?: boolean,
    public field3blank?: boolean,
    public field4a?: string,
    public field4b?: string,
    public field4space?: boolean,
    public field4blank?: boolean,
    public field5a?: string,
    public field5b?: string,
    public field5space?: boolean,
    public field5blank?: boolean,
    public field6a?: string,
    public field6b?: string,
    public field6space?: boolean,
    public field6blank?: boolean,
    public field7a?: string,
    public field7b?: string,
    public field7space?: boolean,
    public field7blank?: boolean,
    public field8a?: string,
    public field8b?: string,
    public field8space?: boolean,
    public field8blank?: boolean,
    public authorName?: string,
    public authorImage?: string,
    public footerText?: string,
    public footerImage?: string,
    public thumbnail?: string,
    public pail?: number,
  ) {  }

}