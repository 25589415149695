import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { title } from 'process';
import { Glds } from '../_Models/glds';
import { User } from '../_Models/User';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(public cookie: CookieService, private http: HttpClient, private router: Router, private titleService: Title) { }

  public user = new User();
  global: Glds = new Glds;

  twofactor : boolean = false;

  code: string = '';

  ngOnInit(): void {
    this.titleService.setTitle("התחברות");
    this.getIPAddress();
    this.cookies();
  }

  cookies() {
    if (this.cookie.get('token') && this.cookie.get('token') != null && this.cookie.get('token') != undefined) {
      this.router.navigate(["/panel"]);
    }
  }

  getIPAddress(): void
  {
      console.log("the ip address");
    this.http.get("https://api.ipify.org/?format=json").subscribe((res:any)=>{
      this.user.ipa = res.ip;
      console.log(res.ip);
    }).add(() => {
        this.cookie.set("ip", this.user.ipa + "" , 600, "/");
    });;
  }

  sendForm() {

    console.log("User Details:");
    console.log(this.user);

    this.user.domain = this.global.domain;

    let headers = new HttpHeaders({
      // 'token' : this.cookie.get("token"),
      'ip' : this.cookie.get("ip")
    });

    let ob = this.http.post<User>(this.global.serverLogin + '/twocode/login', this.user, {headers});
    ob.subscribe(token => {

      console.log(token);

      this.cookie.set('token', token.token + "");
      this.cookie.set('token', token.token + "", 0, '/panel');

      this.cookie.set('name', token.username + "");
      this.cookie.set('name', token.username + "", 0, '/panel');

      this.cookie.set('id', token.id + "");
      this.cookie.set('id', token.id + "", 0, '/panel');

      this.user = token;

      // if ( (token.username != null && token.token == null) || (token.username != "" && token.token == "") || (token.username.length > 1 && token.token.length < 1)) {
        this.twofactor = token.twoauth;

    }, err => {
      alert('אחד מהפרטים שהזנת שגווים');
    }).add(() => {
      console.log(this.user);
      if (this.cookie.get('token') && this.cookie.get('token') !== null && this.cookie.get('token') !== undefined && this.cookie.get('token').length > 10) {
        console.log('navigate');
         this.router.navigate(["/panel"]);
        // window.location.replace("/panel");
      }
    });
  }

 login()
  {
    console.log("the ip address");
    this.http.get("https://api.ipify.org/?format=json").subscribe((res:any)=>{
      this.user.ipa = res.ip;
      console.log(res.ip);
    }).add(() => {
      this.sendForm();
    });
  }

  twocode() {

    console.log("User Details:");
    console.log(this.user);

    this.user.twocode = this.code;
    
    let headers = new HttpHeaders({
      // 'token' : this.cookie.get("token"),
      'ip' : this.cookie.get("ip")
    });

    let ob = this.http.post<User>(this.global.serverLogin + '/twocode/twocode/guard', this.user, {headers});
    ob.subscribe(token => {

      console.log(token);

      this.cookie.set('token', token.token + "");
      this.cookie.set('token', token.token + "", 0, '/panel');

      this.cookie.set('name', token.username + "");
      this.cookie.set('name', token.username + "", 0, '/panel');

      // this.cookie.set('id', token.id + "");
      // this.cookie.set('id', token.id + "", 0, '/panel');

      this.router.navigate(["/panel"]);

    }, err => {
      alert('אחד מהפרטים שהזנת שגווים');
    }).add(() => {
      console.log(this.user)
      if (this.cookie.get('token') && this.cookie.get('token') !== null && this.cookie.get('token') !== undefined && this.cookie.get('token').length > 10) {
        console.log('navigate');
         this.router.navigate(["/panel"]);
        // window.location.replace("/panel");
      }
    });
  }

  createcode(code: string) {
    if (this.code.length < 6) {
      this.code = this.code + code;
    }
  }

}
