import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, interval, Subscription } from 'rxjs';
import { Msg } from 'src/app/chat/_Models/msg/msg';
import { Glds } from 'src/app/_Models/glds';
import { ActivatedRoute } from '@angular/router';
import { Channel } from 'src/app/_Models/channel';



import * as $ from 'jquery';
import { Embed } from 'src/app/_Models/Embed';
import { BotMsg } from 'src/app/_Models/BotMsg';
import { BotReact } from 'src/app/_Models/BotReact';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.css']
})
export class TextComponent implements OnInit {
  @ViewChild('scrollMe', { static: true }) private myScrollContainer: ElementRef;
  public msg: Msg[];
  editMsg: Msg = new Msg;
  private updateSubscription: Subscription;
  global: Glds = new Glds;
  
  msgSrc: String = '/assets/icons/X-white.png';

  serverId : number = 0;
  lastServerId: number = 0;
  channelId: number = 0;

  numTitles: number = 1;
  maxTitles: number = 8;

  channelsWidth: number = 0;
  chatWidth: number = 0;

  boxWidth: number = 0;
  boxHeight: number = 0;
  rowWidth: number = 0;

  chatHeight: number = 0;

  channels: Channel[];

  embed: Embed = new Embed();
  botMsg: BotMsg = new BotMsg();
  botReact: BotReact = new BotReact();
  reacts: BotReact[];

  embeds: Embed[];

  curReact: string;
  lastChannelId: number;

  hisEmbed: number;

  public serverName: string;
  public channelName: string;

  ToolsBot: boolean;

  constructor(private http: HttpClient, private route: ActivatedRoute) { }

  ngOnInit() {
    this.buildBox();
    this.serverId = null;
    this.route.params.subscribe(params => {
      this.serverId = params['serverId'];
      this.channelId = params['channelId'];
      this.loadMessages();
      this.loadServerChannels();
    });
    this.loadMessages();
    this.loadServerChannels();
    this.updateSubscription = interval(60000).subscribe(
      (val) => { this.loadMessages()
    });


  }

  buildBox() {
    var height = 0;
    if (window.innerHeight > 1200) {
      height = window.innerHeight * 0.6;
      $('.msgs').css("height", height + "px") + " !important";
    } else {
      height = window.innerHeight * 0.55;
      $('.msgs').css("height", height + "px") + " !important";
    }

    if (window.innerWidth < 1600) {
      this.boxWidth = window.innerWidth * 0.948;
    } else {
      this.boxWidth = window.innerWidth * 0.748;
    }


    this.boxHeight = window.innerHeight * 0.748;

    this.channelsWidth = 300;
    $('.channels').css("width", this.channelsWidth + "px") + " !important";

    this.rowWidth = this.boxWidth - 150;
    $('.in').css("width", this.rowWidth + "px") + " !important";
    $('.in').css("height", this.boxHeight + "px") + " !important";

    this.chatWidth = this.rowWidth - this.channelsWidth;
    $('.chat-in').css("width", this.chatWidth + "px") + " !important";

    this.chatHeight = this.boxHeight - 195;
    $('.msgs').css("height", this.chatHeight + "px") + " !important";

  }

  ngAfterViewChecked() {        
    this.scrollToBottom();        
} 

  loadMessages() {

    var url = null;

    if (this.channelId > 0) {
      url = this.global.server + '/msg/server/' + this.serverId + '/' + this.channelId;
    } else {
      url = this.global.server + '/msg/server/' + this.serverId;
    }

    let ob = this.http.get<Msg[]>(url);
    ob.subscribe(userdetails => {
      this.msg = userdetails;
      this.channelName = this.msg[0].channelName;
      this.serverName = this.msg[0].serverName;
    }, err => {
      console.log(this.msg);
    }).add(() => {
      this.scrollToBottom();
      if (this.serverId != this.msg[0].serverId) {
        this.loadMessages();
      }
    });

    
    console.log(this.msg);

  }

  loadEditMessage(event) {

    var url = null;

      url = this.global.server + '/msg/id/' + event;

    let ob = this.http.get<Msg>(url);
    ob.subscribe(details => {
      this.editMsg = details;
    }, err => {
      console.log(this.msg);
    });
    
    console.log(this.msg);

  }

  loadServerChannels() {
    let ob = this.http.get<Channel[]>(this.global.server + '/msg/channels/' + this.serverId);
    ob.subscribe(details => {
      this.channels = details;
    }, err => {
      console.log(this.channels);
    }).add(() => {
      if (this.serverId != this.msg[0].serverId) {
        this.loadMessages();
      }
    });

    console.log(this.channels);
    this.lastServerId = this.serverId;

  }

  loadEmbedsHistory() {
    let ob = this.http.get<Embed[]>(this.global.server + '/embed/server/' + this.serverId);
    ob.subscribe(details => {
      this.embeds = details;
    }, err => {
      console.log(this.embeds);
    });

    console.log(this.channels);
    this.lastServerId = this.serverId;

  }

  loadEmbedFromHistory() {
    console.log("current Embed id is " + this.hisEmbed);

    if (this.hisEmbed == 0) {
      this.embed = new Embed();
      this.numTitles = 1;
      return;
    }

    let ob = this.http.get<Embed>(this.global.server + '/embed/' + this.hisEmbed);
    ob.subscribe(details => {
      this.embed = details;
    }, err => {
      console.log(this.embeds);
    });

    if (this.embed.field2a !== "") {
      this.numTitles = 2;
    }
    if (this.embed.field3a !== "") {
      this.numTitles = 3;
    }
    if (this.embed.field4a !== "") {
      this.numTitles = 4;
    }
    if (this.embed.field5a !== "") {
      this.numTitles = 5;
    }
    if (this.embed.field6a !== "") {
      this.numTitles = 6;
    }
    if (this.embed.field7a !== "") {
      this.numTitles = 7;
    }
    if (this.embed.field8a !== "") {
      this.numTitles = 8;
    }

  }

  activeChannel(event) {
    if(event == this.channelId) {
      return "#393C43";
    } else {
      return null;
    }
  }

  activeServer(event) {
    if(event == this.serverId) {
      return "rgba(0, 0, 0, 0.419)";
    } else {
      return null;
    }
  }

  scrollToBottom(): void {
    try { 

      if (this.lastChannelId !== this.channelId) {
        console.log("Is Down")
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
        this.lastChannelId = this.channelId;
        return;
      }

      if (this.myScrollContainer.nativeElement.scrollTop <= 100) {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
      } else if (this.myScrollContainer.nativeElement.scrollTop <= this.myScrollContainer.nativeElement.scrollHeight) {

      } else {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
      }
    } catch(err) { }      
}

  openEmbed() {
    $('.createEmbed').css("visibility", "visible") + " !important";
    var height = 0;
    height = window.innerHeight * 0.83;
    $('.container').css("max-height", height + "px") + " !important";
    this.embed = new Embed();
    this.hisEmbed = 0;
    this.numTitles = 1;
    this.loadEmbedsHistory();
  }

  openTools(serverId:string, channelId:string, messageId:string, id:string, isbot: boolean) {
    $('.tools').css("visibility", "visible") + " !important";
    this.botReact.serverId = serverId;
    this.botReact.channelId = channelId;
    this.botReact.messageId = messageId;
    this.ToolsBot = isbot;

    this.loadReacts();
    this.loadEditMessage(id);
    // var height = 0;
    // height = window.innerHeight * 0.83;
    // $('.container').css("max-height", height + "px") + " !important";
    // this.embed = new Embed();
    // this.hisEmbed = 0;
    // this.numTitles = 1;
    // this.loadEmbedsHistory();
  }

  closeEmbed() {
    $('.createEmbed').css("visibility", "hidden") + " !important";
  }

  closeTools() {
    $('.tools').css("visibility", "hidden") + " !important";
  }

  loadReacts() {
    let ob = this.http.get<BotReact[]>(this.global.server + '/reaction/server/' + this.serverId);
    ob.subscribe(details => {
      this.reacts = details;
    }, err => {
      console.log(this.reacts);
    }).add(() => {
      this.replaceAllEmoji();
    });
  }

  sendEmbed() {
    this.embed.channelId = this.channelId.toString();
    this.embed.channelName = this.channelName;
    this.embed.serverId = this.serverId.toString();
    this.embed.serverName = this.serverName;

    let ob = this.http.post<Embed>(this.global.server + '/embed', this.embed);
    ob.subscribe(details => {
    }, err => {
      console.log("Error Send Embed");
    });

    this.closeEmbed();
  }

  sendReaction(serverId:string, messageId:string, channelId:string) {
    if (this.botReact == "") {
      return;
    }
    this.botReact.reaction = this.curReact;

    let ob = this.http.post<BotReact>(this.global.server + '/botreact', this.botReact);
    ob.subscribe(details => {
    }, err => {
      console.log("Error Send Embed");
    });

    this.curReact = "";
    this.closeTools();
  }

  sendBotMsg() {
    this.botMsg.channelId = this.channelId.toString();
    this.botMsg.serverId = this.serverId.toString();

    let ob = this.http.post<BotMsg>(this.global.server + '/botmsg', this.botMsg);
    ob.subscribe(details => {
    }, err => {
      console.log("Error Send BotMsg");
    }).add(() => {
      this.loadMessages();
    });

    this.botMsg.text = '';

  }

  editMessage() {
    this.editMsg.filtredText = "edit";

    console.log(this.editMsg);

    let ob = this.http.post<Msg>(this.global.server + '/msg/server/edit', this.editMsg);
    ob.subscribe(details => {
    }, err => {
      console.log("Error Send Edit Message");
    }).add(() => {
      this.closeTools();
    });

  }

  addEmoji($event){

  }

  msgStyle(event, event2) {
    let style = {};

    if(event2 == 'deleted') {
      style = {
        'border' : '3px inset #DC3545',
        'opacity': '1'
      }
    }

    if(event) {
      style = {
        'border' : '1px inset #13E6FF',
        'float' : 'left',
        'opacity': '1'
      }
    }


    return style;
  }

  replaceAllEmoji() {
    for (let reac of this.reacts) {
      reac.value1  = "&#x" + reac.value1 + ";";
    }
  }

}
