import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Msg } from '../chat/_Models/msg/msg';
import { Glds } from '../_Models/glds';
import { Product } from '../_Models/Product';
import { Server } from '../_Models/server';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.css']
})
export class ProductDetailsComponent implements OnInit {

  constructor(private httpClient: HttpClient, private route: ActivatedRoute) { }

  global: Glds = new Glds;
  public serverlist = new Server();
  messages: Msg[] = [];
  productId: String;

  message: Msg = new Msg();

  products: Product[] = [];

  ngOnInit() {
    this.productId = null;
    this.route.params.subscribe(params => {
      this.productId = params['id'];
      this.loadProduct(this.productId);
    });
  }

  loadProduct(event) {
    let ob = this.httpClient.get<Product[]>(this.global.server + '/product/' + event);
    ob.subscribe(logs => {
      console.log(logs);
      this.products = logs;
    }, err => {

    }).add(() => {
      // this.buildBox();
      this.message = this.messages[this.messages.length - 1];
    });
  }

}
