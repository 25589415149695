import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { User } from '../_Models/User';
import { Glds } from '../_Models/glds';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root'
})
export class AdminGuardService implements CanActivate {
    
    public user = new User();
    isAdmin: boolean = false;

    global: Glds = new Glds;

    constructor(private router: Router,public cookie: CookieService,private http: HttpClient) {
      this.user.username = this.cookie.get('name');
      this.user.token = this.cookie.get('token');
      this.user.domain = this.global.domain;
      this.user.ipa = this.cookie.get('ip');
     }

     ngOnInit(): void {
      this.getIPAddress();
  }
    
  public canActivate(): Observable<boolean> {

    var subject = new Subject<boolean>();

    let headers = new HttpHeaders({
      'token' : this.cookie.get("token"),
      'ip' : this.cookie.get("ip")
    });

    let ob = this.http.post<boolean>(this.global.server + '/twocode/guard', this.user, {headers});
    ob.subscribe(success => {
        this.isAdmin = success;
        subject.next(success);
      },err => {
          this.cookie.deleteAll("/");
          this.cookie.deleteAll("/panel");
          subject.next(false);
          this.router.navigate(["/login"]);
          window.location.reload();
        });
        
        return subject.asObservable();

}

    getIPAddress()
    {
        console.log("the ip address");
      this.http.get("https://api.ipify.org/?format=json").subscribe((res:any)=>{
        this.user.ipa = res.ip;
        console.log(res.ip);
      });
    }
    
}
