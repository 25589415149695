export class BotMsg {

  constructor(
    public id?: number,
    public serverId?: string,
    public channelId?: string,
    public text?: string,
    public pail?: number,
  ) {  }

}