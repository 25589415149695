import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Glds } from '../_Models/glds';
import { Server } from '../_Models/server';

import * as $ from 'jquery';
import { ServerDisplay } from '../_Models/ServerDisplay';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {

  public serverlist = new Server();
  global: Glds = new Glds;

  boxWidth: number = 0;
  serversWidth: number = 0;
  serversHeight: number = 0;
  otherWidth: number = 0;

  serversDisplay: ServerDisplay[] = [];

  serverId: string = "911731004123803668";

  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title) { }

  ngOnInit() {
    this.titleService.setTitle("בוט - צ׳אט");
    this.buildBox();
    this.serverId = this.route.snapshot.paramMap.get('serverId');
    // this.loadServerList();
    this.loadServerDisplayList();
  }

  buildBox() {
    var height = 0;
    height = window.innerHeight * 0.748;
    $('.chat').css("height", height + "px") + " !important";

    
    if (window.innerWidth < 1600) {
      this.boxWidth = window.innerWidth * 0.948;
      $('.row').css("width", this.boxWidth + "px") + " !important";
    } else {
      this.boxWidth = window.innerWidth * 0.748;
      $('.row').css("width", this.boxWidth + "px") + " !important";
    }

    this.serversWidth = 150;
    this.serversHeight = window.innerHeight * 0.748;
    $('.servers').css("width", this.serversWidth + "px") + " !important";
    $('.servers').css("height", this.serversHeight + "px") + " !important";
    
    this.otherWidth = this.boxWidth - this.serversWidth;
    $('.chat').css("width", this.otherWidth + "px") + " !important";
    $('.chat').css("height", this.serversHeight + "px") + " !important";

    // $('.servers').css("height", height + "px") + " !important";
  }

  loadServerList() {
    let ob = this.http.get<Server>(this.global.server + '/msg/serverlist');
    ob.subscribe(details => {
      this.serverlist = details;
    }, err => {
    });

  }

  loadServerDisplayList() {
    let ob = this.http.get<ServerDisplay[]>(this.global.server + '/serverdisplay/chat');
    ob.subscribe(details => {
      this.serversDisplay = details;
    }, err => {
    });
  }

  activeServer(event) {
    let style = {};

    if(event == this.serverId) {
      style = {
        'border' : '8px inset rgb(171, 171, 255)',
        'opacity': '1'
      }
    } else {
      style = {
        'opacity': '.3'
      }
    }
    return style;
  }

}
