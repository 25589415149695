import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Glds } from '../_Models/glds';
import { Logs } from '../_Models/Logs';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.css']
})
export class LogsComponent implements OnInit {

  constructor(private httpClient: HttpClient, private titleService: Title) { }

  logs: Logs[] = [];
  global: Glds = new Glds;

  ngOnInit(): void {
    // this.handleLogsList();
    this.titleService.setTitle("בוט - לוגים");
  }

  handleLogsList() {
    let ob = this.httpClient.get<Logs[]>(this.global.server + '/logs');
    ob.subscribe(logs => {
      this.logs = logs;
    }, err => {
      this.handleLogsList();
    }).add(() => {
      // this.buildBox();
    });
    
  }

  columnColor(event: String): String{
    if(event == 'SECURITY_LOGIN') {
      return "#DC3545";
    }
    if(event == 'ERROR_SQL') {
      return '#006EC0';
    }
    if(event == 'INFO_UPDATE' || event == 'INFO_CREATE') {
      return '#006EC0';
    }
  }
}
