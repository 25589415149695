export class Server {

    constructor(
        public serverId?: string,
        public serverName?: String,
        public serverAvatar?: String,
      ) {  }


}
