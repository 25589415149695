import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Msg } from '../chat/_Models/msg/msg';
import { Glds } from '../_Models/glds';
import { Server } from '../_Models/server';

@Component({
  selector: 'app-user-messages',
  templateUrl: './user-messages.component.html',
  styleUrls: ['./user-messages.component.css']
})
export class UserMessagesComponent implements OnInit {

  constructor(private httpClient: HttpClient, private route: ActivatedRoute) { }

  global: Glds = new Glds;
  public serverlist = new Server();
  messages: Msg[] = [];
  userId: String;

  message: Msg = new Msg();

  ngOnInit() {
    this.userId = null;
    this.route.params.subscribe(params => {
      this.userId = params['userId'];
      this.loadMessages(this.userId);
    });
    this.loadMessages(this.userId);
  }

  loadMessages(event) {
    let ob = this.httpClient.get<Msg[]>(this.global.server + '/msg/user/' + event);
    ob.subscribe(logs => {
      console.log(logs);
      this.messages = logs;
    }, err => {

    }).add(() => {
      // this.buildBox();
      this.message = this.messages[this.messages.length - 1];
    });
    
  }

  columnColor(event: String): String{
    if(event == 'SECURITY_LOGIN') {
      return "#DC3545";
    }
    if(event == 'ERROR_SQL') {
      return '#006EC0';
    }
    if(event == 'INFO_UPDATE' || event == 'INFO_CREATE') {
      return '#006EC0';
    }
  }

  activeServer(event) {
    let style = {};

    if(event == this.userId) {
      style = {
        'border' : '8px inset rgb(171, 171, 255)',
        'opacity': '1'
      }
    } else {
      style = {
        'opacity': '.3'
      }
    }
    return style;
  }

  filStyle(event) {
    let style = {};

    if(event == 'messaage') {
      style = {
        // 'border' : '8px inset rgb(171, 171, 255)',
        'opacity': '1'
      }
    } else if (event == 'deleted'){
      style = {
        'background-color' : 'red',
        'opacity': '.8'
      }
    }
    return style;
  }

  getFilTeur(event) {
    if (event == 'message') {
      return 'הודעה';
    } else if (event == 'deleted') {
      return 'נמחקה';
    }

    return null;

  }

}
