export class ServerDisplay {

  constructor(
    public id?: string,
    public server_id?: string,
    public server_name?: string,
    public server_avatar?: string,
    public server_users?: number,
    public show_chat?: boolean,
    public show_settings?: boolean,
    public custom_avatar?: boolean,
    public value1?: String,
    public value2?: String,
  ) {  }

}