import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Glds } from '../_Models/glds';
import { UserDisplay } from '../_Models/UserDisplay';
import * as $ from 'jquery';
import { LevelEntry } from '../_Models/LevelEntry';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  userDisplay: UserDisplay = new UserDisplay();

  users: UserDisplay[] = [];

  global: Glds = new Glds;

  search: string = '';

  levelAction: string = '';
  level: number = 0;
  pointsAction: string = '';
  points: number = 0;

  isLoading: boolean = true;

  curLevel: LevelEntry = new LevelEntry();

  constructor(private httpClient: HttpClient, private titleService: Title) { }

  ngOnInit() {
    this.loadServerMembers();
    this.titleService.setTitle("בוט - משתמשים");
  }

  loadServerMembers() {
    this.isLoading = true;
    let ob = this.httpClient.get<UserDisplay[]>(this.global.server + '/userdisplay');
    ob.subscribe(details => {
      this.users = details;
    }, err => {
      console.log(err);
    }).add(() => {
      this.isLoading = false;
    });

    console.log(this.users);

  }

  
  loadServerMember(event) {
    this.isLoading = true;
    this.userDisplay = new UserDisplay();
    this.level = 0;
    this.points = 0;
    this.levelAction = '';
    this.pointsAction = '';
    let ob = this.httpClient.get<UserDisplay>(this.global.server + '/userdisplay/' + event);
    ob.subscribe(details => {
      this.userDisplay = details;
    }, err => {
      console.log(err);
    }).add(() => {
      this.isLoading = false;
      console.log("number " + this.users.length);
    });

    console.log(this.users);

  }

  searchMember() {
    if (this.search == '') {
      this.loadServerMembers()
      return;
    }
    this.isLoading = true;
    let ob = this.httpClient.get<UserDisplay[]>(this.global.server + '/userdisplay/search/' + this.search);
    ob.subscribe(details => {
      this.users = details;
    }, err => {
      console.log(err);
    }).add(() => {
      this.isLoading = false;
      console.log("number " + this.users.length);
    });

    console.log(this.users);

  }

  updateMember() {
    if (this.level !== 0) {
      if (this.levelAction == 'Add') {
        this.curLevel.level = ( Number(this.userDisplay.user_level) + Number(this.level)) + '';
      }
      if (this.levelAction == 'Update') {
        this.curLevel.level = this.level + '';
      }
    } else {
      if (this.levelAction == 'Reset') {
        this.curLevel.level = '0';
      } else {
       this.curLevel.level = this.userDisplay.user_level; 
      }
    }
    if (this.points !== 0) {
      if (this.pointsAction == 'Add') {
        this.curLevel.points = (Number(this.userDisplay.user_points) + Number(this.points)) + '';
      }
      if (this.pointsAction == 'Update') {
        this.curLevel.points = this.points + '';
      }
    } else {
      if (this.pointsAction == 'Reset') {
        this.curLevel.points = '0';
      } else {
        this.curLevel.points = this.userDisplay.user_points; 
      }
    }

    this.curLevel.userId = this.userDisplay.user_id;
    this.curLevel.userName = this.userDisplay.user_name;
    this.curLevel.exp = this.userDisplay.user_exp;
    this.curLevel.need_exp = this.userDisplay.user_needexp;

    let ob = this.httpClient.post<LevelEntry>(this.global.server + '/lvl/update', this.curLevel);
    ob.subscribe(details => {
      console.log(this.curLevel);
    }, err => {
      console.log(err);
    }).add(() => {
      this.closeLevelSystem();
      this.loadServerMembers();
    });

  }

  openLevelSystem(event) {
    this.loadServerMember(event);
    $('.LevelSystemModal').css("visibility", "visible") + " !important";
  }

  closeLevelSystem() {
    $('.LevelSystemModal').css("visibility", "hidden") + " !important";
  }

}
