import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Glds } from '../_Models/glds';
import { Logs } from '../_Models/Logs';

@Component({
  selector: 'app-logs-dev',
  templateUrl: './logs-dev.component.html',
  styleUrls: ['./logs-dev.component.css']
})
export class LogsDevComponent implements OnInit {

  constructor(private httpClient: HttpClient) { }

  logs: Logs[] = [];
  global: Glds = new Glds;

  ngOnInit(): void {
    this.handleLogsList();
  }

  handleLogsList() {
    let ob = this.httpClient.get<Logs[]>(this.global.server + '/logs');
    ob.subscribe(logs => {
      this.logs = logs;
    }, err => {
      this.handleLogsList();
    }).add(() => {
      // this.buildBox();
    });
    
  }

  columnColor(event: String): String{
    if(event.startsWith('SECURITY_') || event.startsWith('ERROR_') || event.startsWith('INFO')) {
      return "#DC3545";
    }
    if(event.startsWith('USER_')) {
      // return "#007BE8";
    }
    if(event.startsWith('ROLE_') || event.startsWith('EMOTE_')) {
      return "#17A2B8";
    }
    if(event.endsWith('_WORD') || event == "USER_BAN" || event == "USER_KICK" || event.startsWith('REPORT_')) {
       return "#FFB900";
    }
  }

}
