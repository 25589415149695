export class WordE {

  constructor(
    public id?: number,
    public text?: string,
    public value?: string,
    public value2?: string,
    public serverId?: string,
  ) {  }

}