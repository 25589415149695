import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import * as $ from 'jquery';
import { Observable, Subject } from 'rxjs';
import { Birth } from '../_Models/Birth';
import { BotMsg } from '../_Models/BotMsg';
import { Channel } from '../_Models/channel';
import { Glds } from '../_Models/glds';
import { Server } from '../_Models/server';
import { ServerDisplay } from '../_Models/ServerDisplay';
import { Settings } from '../_Models/Settings';
import { SyncRole } from '../_Models/SyncRole';
import { TextR } from '../_Models/TextR';
import { UserDisplay } from '../_Models/UserDisplay';
import { WordE } from '../_Models/WordE';

@Component({
  selector: 'app-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.css']
})
export class ActionComponent implements OnInit {

  constructor(private http: HttpClient, private titleService: Title) { }

  channels: Channel[] = [];
  public serverlist: Server[] = [];

  global: Glds = new Glds;

  wordsc: string;
  wordscserver: string;

  serverId: string;

  channelsServerId: string;
  messagesServerId: string;
  blackServerId: string;
  ahrzaServerId: string;
  imgLinkServerId: string;
  syncRolesServerId: string;
  birthServerId: string;

  channelNumber: number = 50;

  generalChannel: Channel = new Channel();
  notChannel: Channel = new Channel();
  joinChannel: Channel = new Channel();
  leaveChannel: Channel = new Channel();
  logsChannel: Channel = new Channel();
  policeChannel: Channel = new Channel();

  setting: Settings = new Settings();

  joinText?: TextR = new TextR();
  leaveText?: TextR = new TextR();
  levelText?: TextR = new TextR();
  boostText?: TextR = new TextR();
  ahrzaText?: TextR = new TextR();
  reportText?: TextR = new TextR();

  birthText?: TextR = new TextR();
  birth1Text?: TextR = new TextR();
  birth2Text?: TextR = new TextR();
  birth3Text?: TextR = new TextR();
  birth4Text?: TextR = new TextR();

  births: Birth[] = [];
  birthsMon: Birth[] = [];

  WarningWord: WordE[] = [];
  BlackWord: WordE[] = [];
  BlackKickWord: WordE[] = [];
  BlackBanWord: WordE[] = [];
  singleWord: WordE = new WordE();

  botMsg: BotMsg = new BotMsg();

  syncRolesList: SyncRole[] = [];

  curServer: ServerDisplay = new ServerDisplay();

  serversDisplay: ServerDisplay[] = [];

  loadWord: boolean = false;

  new: boolean;

  sendGeneral: boolean;
  sendNot: boolean;

  updateSetting: Settings = new Settings();

  linkRole: Settings = new Settings();
  imgRole: Settings = new Settings();

  imgC: Settings[] = [];
  linkC: Settings[] = [];

  ngOnInit() {
    this.loadServerList();
    this.loadServerDisplayList();
    this.loadBirthList();
    this.loadBirthListMonth();
    this.titleService.setTitle("בוט - פעולות");
  }

  loadServerList() {
    let ob = this.http.get<Server[]>(this.global.server + '/msg/serverlist');
    ob.subscribe(details => {
      this.serverlist = details;
    }, err => {
    });
  }

  loadServerDisplayList() {
    let ob = this.http.get<ServerDisplay[]>(this.global.server + '/serverdisplay/settings');
    ob.subscribe(details => {
      this.serversDisplay = details;
    }, err => {
    });
  }

  loadTexts(event) {

    this.loadJoinText(event);
    this.loadLeaveText(event);
    this.loadLevelText(event);
    this.loadBoostText(event);
    this.loadAhrzaText(event);
    this.loadReportText(event);

  }

  loadJoinText(event) {
    this.joinText = new TextR();
    let ob = this.http.get<TextR>(this.global.server + '/txt/join/' + event);
    ob.subscribe(details => {
      if (details == null) {
        this.joinText = new TextR();
      } else {
        this.joinText = details;
      }
    }, err => {
      this.joinText = new TextR();
    });

    this.messagesServerId = event;

  }

  updateJoinText() {

    if (this.joinText.id == undefined) {
      this.joinText.serverId = this.messagesServerId;
      this.joinText.value = 'join';
    }

    this.wordsc = ' הודעת כניסת משתמש ';
    let ob = this.http.post<TextR>(this.global.server + '/txt/update/', this.joinText);
    ob.subscribe(details => {
    }, err => {
      console.log(err);
    }).add(() => {
      this.openSuccessWord(this.wordsc);
    });

  }

  loadLeaveText(event) {
    this.leaveText = new TextR();
    let ob = this.http.get<TextR>(this.global.server + '/txt/leave/' + event);
    ob.subscribe(details => {
      if (details == null) {
        this.leaveText = new TextR();
      } else {
        this.leaveText = details;
      }
    }, err => {
    });
    this.messagesServerId = event;

  }

  updateLeaveText() {

    if (this.leaveText.id == undefined) {
      this.leaveText.serverId = this.messagesServerId;
      this.leaveText.value = 'leave';
    }

    this.wordsc = ' הודעת יציאת משתמש ';
    let ob = this.http.post<TextR>(this.global.server + '/txt/update/', this.leaveText);
    ob.subscribe(details => {
    }, err => {
    }).add(() => {
      this.openSuccessWord(this.wordsc);
    });

  }

  loadLevelText(event) {
    this.levelText = new TextR();
    let ob = this.http.get<TextR>(this.global.server + '/txt/level/' + event);
    ob.subscribe(details => {
      if (details == null) {
        this.levelText = new TextR();
      } else {
        this.levelText = details;
      }
    }, err => {
    });

    this.messagesServerId = event;

  }

  updateLevelText() {

    if (this.levelText.id == undefined) {
      this.levelText.serverId = this.messagesServerId;
      this.levelText.value = 'level';
    }

    this.wordsc = ' הודעת עליית רמת משתמש ';
    let ob = this.http.post<TextR>(this.global.server + '/txt/update/', this.levelText);
    ob.subscribe(details => {
    }, err => {
    }).add(() => {
      this.openSuccessWord(this.wordsc);
    });

  }

  loadBoostText(event) {
    this.boostText = new TextR();
    let ob = this.http.get<TextR>(this.global.server + '/txt/boost/' + event);
    ob.subscribe(details => {
      if (details == null) {
        this.boostText = new TextR();
      } else {
        this.boostText = details;
      }
    }, err => {
    });

    this.messagesServerId = event;

  }

  updateBoostText() {

    if (this.boostText.id == undefined) {
      this.boostText.serverId = this.messagesServerId;
      this.boostText.value = 'boost';
    }

    this.wordsc = ' הודעת בוסט שרת ';
    let ob = this.http.post<TextR>(this.global.server + '/txt/update/', this.boostText);
    ob.subscribe(details => {
    }, err => {
    }).add(() => {
      this.openSuccessWord(this.wordsc);
    });
  }

  loadAhrzaText(event) {
    this.ahrzaText = new TextR();
    let ob = this.http.get<TextR>(this.global.server + '/txt/ahrza/' + event);
    ob.subscribe(details => {
      if (details == null) {
        this.ahrzaText = new TextR();
      } else {
        this.ahrzaText = details;
      }
    }, err => {
    }).add(() => {
      this.ahrzaServerId = event;
      this.channelsServerId = event;

      this.loadCurrentChannels();
    });
    
    this.messagesServerId = event;

  }

  updateAhrzaText() {

    if (this.ahrzaText.id == undefined) {
      this.ahrzaText.serverId = this.messagesServerId;
      this.ahrzaText.value = 'ahrza';
    }

    this.wordsc = ' הודעת הכרזה שרת ';
    let ob = this.http.post<TextR>(this.global.server + '/txt/update/', this.ahrzaText);
    ob.subscribe(details => {
    }, err => {
    }).add(() => {
      this.openSuccessWord(this.wordsc);
    });
  }

  loadReportText(event) {
    this.reportText = new TextR();
    let ob = this.http.get<TextR>(this.global.server + '/txt/report/' + event);
    ob.subscribe(details => {
      if (details == null) {
        this.reportText = new TextR();
      } else {
        this.reportText = details;
      }
    }, err => {
    }).add(() => {
      this.ahrzaServerId = event;
      this.channelsServerId = event;

      this.loadCurrentChannels();
    });
    
    this.messagesServerId = event;

  }

  updateReportText() {

    if (this.reportText.id == undefined) {
      this.reportText.serverId = this.messagesServerId;
      this.reportText.value = 'report';
    }

    this.wordsc = ' הודעת תלונה שרת ';
    let ob = this.http.post<TextR>(this.global.server + '/txt/update/', this.reportText);
    ob.subscribe(details => {
    }, err => {
    }).add(() => {
      this.openSuccessWord(this.wordsc);
    });
  }

  loadBirths(event) {

    this.loadBirthText(event);
    this.loadBirth1Text(event);
    this.loadBirth2Text(event);
    this.loadBirth3Text(event);
    this.loadBirth4Text(event);

    this.birthServerId = event;

  }

  loadBirthText(event) {
    this.birthText = new TextR();
    let ob = this.http.get<TextR>(this.global.server + '/txt/birth/' + event);
    ob.subscribe(details => {
      if (details == null) {
        this.birthText = new TextR();
      } else {
        this.birthText = details;
      }
    }, err => {
      this.birthText = new TextR();
    });
    this.birthServerId = event;

  }

  updateBirthText() {

    if (this.birthText.id == undefined) {
      this.birthText.serverId = this.birthServerId;
      this.birthText.value = 'birth';
    }
    
    this.wordsc = ' הודעת יום הולדת ';
    let ob = this.http.post<TextR>(this.global.server + '/txt/update/', this.birthText);
    ob.subscribe(details => {
      this.birthText = details;
    }, err => {
    }).add(() => {
      this.loadBirthText(this.birthServerId);
    });

  }

  loadBirth1Text(event) {
    this.birth1Text = new TextR();
    let ob = this.http.get<TextR>(this.global.server + '/txt/birth1/' + event);
    ob.subscribe(details => {
      if (details == null) {
        this.birth1Text = new TextR();
      } else {
        this.birth1Text = details;
      }
    }, err => {
      this.birth1Text = new TextR();
    });
    this.birthServerId = event;

  }

  updateBirth1Text() {

    if (this.birth1Text.id == undefined) {
      this.birth1Text.serverId = this.birthServerId;
      this.birth1Text.value = 'birth1';
    }

    this.wordsc = ' הודעת היכרות ';
    let ob = this.http.post<TextR>(this.global.server + '/txt/update/', this.birth1Text);
    ob.subscribe(details => {
      this.birth1Text = details;
    }, err => {
    }).add(() => {
      this.loadBirth1Text(this.birthServerId);
    });

  }

  loadBirth2Text(event) {
    this.birth2Text = new TextR();
    let ob = this.http.get<TextR>(this.global.server + '/txt/birth2/' + event);
    ob.subscribe(details => {
      if (details == null) {
        this.birth2Text = new TextR();
      } else {
        this.birth2Text = details;
      }
    }, err => {
      this.birth2Text = new TextR();
    });
    this.birthServerId = event;

  }

  updateBirth2Text() {

    if (this.birth2Text.id == undefined) {
      this.birth2Text.serverId = this.birthServerId;
      this.birth2Text.value = 'birth2';
    }

    this.wordsc = ' הודעת בחירת יום ';
    let ob = this.http.post<TextR>(this.global.server + '/txt/update/', this.birth2Text);
    ob.subscribe(details => {
      this.birth2Text = details;
    }, err => {
    }).add(() => {
      this.loadBirth2Text(this.birthServerId);
    });

  }

  loadBirth3Text(event) {
    this.birth3Text = new TextR();
    let ob = this.http.get<TextR>(this.global.server + '/txt/birth3/' + event);
    ob.subscribe(details => {
      if (details == null) {
        this.birth3Text = new TextR();
      } else {
        this.birth3Text = details;
      }
    }, err => {
      this.birth3Text = new TextR();
    });
    this.birthServerId = event;

  }

  updateBirth3Text() {

    if (this.birth3Text.id == undefined) {
      this.birth3Text.serverId = this.birthServerId;
      this.birth3Text.value = 'birth3';
    }

    this.wordsc = ' הודעת בחירת חודש ';
    let ob = this.http.post<TextR>(this.global.server + '/txt/update/', this.birth3Text);
    ob.subscribe(details => {
      this.birth3Text = details;
    }, err => {
    }).add(() => {
      this.loadBirth3Text(this.birthServerId);
    });

  }

  loadBirth4Text(event) {
    this.birth4Text = new TextR();
    let ob = this.http.get<TextR>(this.global.server + '/txt/birth4/' + event);
    ob.subscribe(details => {
      if (details == null) {
        this.birth4Text = new TextR();
      } else {
        this.birth4Text = details;
      }
    }, err => {
      this.birth4Text = new TextR();
    });
    this.birthServerId = event;

  }

  updateBirth4Text() {

    if (this.birth4Text.id == undefined) {
      this.birth4Text.serverId = this.birthServerId;
      this.birth4Text.value = 'birth4';
    }

    this.wordsc = ' הודעת בחירת חודש ';
    let ob = this.http.post<TextR>(this.global.server + '/txt/update/', this.birth4Text);
    ob.subscribe(details => {
      this.birth4Text = details;
    }, err => {
    }).add(() => {
      this.loadBirth4Text(this.birthServerId);
    });

  }

  loadBirthList() {
    this.births = [];
    let ob = this.http.get<Birth[]>(this.global.server + '/birthday/order');
    ob.subscribe(details => {
      if (details == null) {
        this.births = [];
      } else {
        this.births = details;
      }

      console.log(details);

    }, err => {
      this.births = [];
    }).add(() => {
      for (var birth of this.births) {
        for (var serverD of this.serversDisplay) {
          if (birth.serverId == serverD.server_id) {
            birth.serverId = serverD.server_name;
            break;
          }
        }
      }

    });

  }

  loadBirthListMonth() {
    this.birthsMon = [];
    let ob = this.http.get<Birth[]>(this.global.server + '/birthday/month');
    ob.subscribe(details => {
      if (details == null) {
        this.birthsMon = [];
      } else {
        this.birthsMon = details;
      }

      console.log(details);

    }, err => {
      this.birthsMon = [];
    });

  }

  loadBirthListMonthBigger() {
    this.birthsMon= [];
    let ob = this.http.get<Birth[]>(this.global.server + '/birthday/month/bigger');
    ob.subscribe(details => {
      if (details == null) {
        this.birthsMon = [];
      } else {
        this.birthsMon = details;
      }

      console.log(details);

    }, err => {
      this.birthsMon = [];
    });

  }

  loadWords(event) {
    this.loadWarningWord(event);
    this.loadBlackWord(event);
    this.loadBlackKickWord(event);
    this.loadBlackBanWord(event);

    this.singleWord = new WordE();
    this.blackServerId = event;
  }

  loadWarningWord(event) {
    this.WarningWord = [];
    let ob = this.http.get<WordE[]>(this.global.server + '/word/action/warning/' + event);
    ob.subscribe(details => {
        this.WarningWord = details;
    }, err => {
      console.log(this.WarningWord);
    });
  }

  loadBlackWord(event) {
    this.BlackWord = [];
    let ob = this.http.get<WordE[]>(this.global.server + '/word/action/black/' + event);
    ob.subscribe(details => {
      this.BlackWord = details;
    }, err => {
      console.log(this.BlackWord);
    });
  }

  loadBlackKickWord(event) {
    this.BlackKickWord = [];
    let ob = this.http.get<WordE[]>(this.global.server + '/word/action/black_kick/' + event);
    ob.subscribe(details => {
      this.BlackKickWord = details;
    }, err => {
      console.log(this.BlackKickWord);
    });
  }

  loadBlackBanWord(event) {
    this.BlackBanWord = [];
    let ob = this.http.get<WordE[]>(this.global.server + '/word/action/black_ban/' + event);
    ob.subscribe(details => {
      this.BlackBanWord = details;
    }, err => {
      console.log(this.BlackBanWord);
    });
  }

  openChannels(serverId:string, channelId:string, messageId:string) {
    $('.ChannelsModal').css("visibility", "visible") + " !important";
  }

  closeChannels() {
    $('.ChannelsModal').css("visibility", "hidden") + " !important";
  }

  openBirthListMonth(serverId:string, channelId:string, messageId:string) {
    $('.BirthListMonthModal').css("visibility", "visible") + " !important";
  }

  closeBirthListMonth() {
    $('.BirthListMonthModal').css("visibility", "hidden") + " !important";
  }

  openBirthList(serverId:string, channelId:string, messageId:string) {
    $('.BirthListModal').css("visibility", "visible") + " !important";
  }

  closeBirthList() {
    $('.BirthListModal').css("visibility", "hidden") + " !important";
  }
  
  openMessages(serverId:string, channelId:string, messageId:string) {
    $('.MessagesModal').css("visibility", "visible") + " !important";
  }

  closeMessages() {
    $('.MessagesModal').css("visibility", "hidden") + " !important";
  }

  openBlackList(serverId:string, channelId:string, messageId:string) {
    $('.BlackListModal').css("visibility", "visible") + " !important";
  }

  closeBlackList() {
    $('.BlackListModal').css("visibility", "hidden") + " !important";
  }

  openAhrza(serverId:string, channelId:string, messageId:string) {
    $('.AhrzaModal').css("visibility", "visible") + " !important";
  }

  closeAhrza() {
    $('.AhrzaModal').css("visibility", "hidden") + " !important";
  }

  openServers(serverId:string, channelId:string, messageId:string) {
    $('.ServersModal').css("visibility", "visible") + " !important";
  }

  closeServers() {
    $('.ServersModal').css("visibility", "hidden") + " !important";
  }

  openImgLink(serverId:string, channelId:string, messageId:string) {
    $('.ImgLinkModal').css("visibility", "visible") + " !important";
  }

  closeImgLink() {
    $('.ImgLinkModal').css("visibility", "hidden") + " !important";
  }

  openBirth(serverId:string, channelId:string, messageId:string) {
    $('.BirthModal').css("visibility", "visible") + " !important";
  }

  closeBirth() {
    $('.BirthModal').css("visibility", "hidden") + " !important";
  }

  openSyncRoles(serverId:string, channelId:string, messageId:string) {
    $('.SyncRolesModal').css("visibility", "visible") + " !important";
    this.loadSyncRoles()
  }

  closeSyncRoles() {
    $('.SyncRolesModal').css("visibility", "hidden") + " !important";
  }

  openSuccessBlack(event) {
    (async () => { 
      this.wordsc = event;
      // Do something before delay
    $('.SuccessBlack').css("visibility", "visible") + " !important";
    await delay(5000);
    $('.SuccessBlack').css("visibility", "hidden") + " !important";
  })();
  }
  
  openSuccessUpBlack(event) {
    (async () => { 
      this.wordsc = event;
      // Do something before delay
    $('.SuccessUpBlack').css("visibility", "visible") + " !important";
    await delay(5000);
    $('.SuccessUpBlack').css("visibility", "hidden") + " !important";
  })();
  }

  openSuccessDltBlack(event) {
    (async () => { 
      this.wordsc = event;
      // Do something before delay
    $('.SuccessDltBlack').css("visibility", "visible") + " !important";
    await delay(5000);
    $('.SuccessDltBlack').css("visibility", "hidden") + " !important";
  })();
  }

  openSuccessWord(event) {
    (async () => { 
      this.wordsc = event;
      // Do something before delay
    $('.SuccessWord').css("visibility", "visible") + " !important";
    await delay(5000);
    $('.SuccessWord').css("visibility", "hidden") + " !important";
  })();
  }

  openSuccessAhrza() {
    (async () => { 
      // Do something before delay
    $('.SuccessAhrza').css("visibility", "visible") + " !important";
    await delay(5000);
    $('.SuccessAhrza').css("visibility", "hidden") + " !important";
  })();
  }

  openSingleWord(serverId:string, value:string) {
    this.blackServerId = serverId;

    if (value != null) {
      this.new = false;
      this.loadSingleWord(value);
      this.loadWord = true;
    } else {
      this.new = true;
      this.singleWord = new WordE();
      this.loadWord = false;
    }

    $('.SingleWordModal').css("visibility", "visible") + " !important";
    $('.SingleWordModal').css("animation:", "sc-animation 2s") + " !important";
  }

  closeSingleWord() {
    $('.SingleWordModal').css("visibility", "hidden") + " !important";
  }

  createWord() {
    this.wordsc = this.singleWord.text;
    this.singleWord.serverId = this.blackServerId;

    if (this.singleWord.text == undefined || this.singleWord.value2 == undefined) {
      return;
    }

    let ob = this.http.post<WordE>(this.global.server + '/word', this.singleWord);
    ob.subscribe(details => {
    }, err => {
      this.loadWord = false;
    }).add(() => {
      this.singleWord = new WordE();
      this.closeSingleWord();
      this.loadWords(this.blackServerId);
      this.openSuccessBlack(this.wordsc);
    });
  }

  updateWord() {
    this.wordsc = this.singleWord.text;
    this.singleWord.serverId = this.blackServerId;
    let ob = this.http.post<WordE>(this.global.server + '/word/update', this.singleWord);
    ob.subscribe(details => {
      this.singleWord = details;
    }, err => {
      this.loadWord = false;
    }).add(() => {
      this.singleWord = new WordE();
      this.closeSingleWord();
      this.loadWords(this.blackServerId);
      this.openSuccessUpBlack(this.wordsc);
    });
  }

  deleteWord() {
    this.wordsc = this.singleWord.text;
    this.singleWord.serverId = this.blackServerId;
    let ob = this.http.post<WordE>(this.global.server + '/word/delete', this.singleWord);
    ob.subscribe(details => {
      this.singleWord = details;
    }, err => {
      this.loadWord = false;
    }).add(() => {
      this.singleWord = new WordE();
      this.closeSingleWord();
      this.loadWords(this.blackServerId);
      this.openSuccessDltBlack(this.wordsc);
    });
  }

  loadSingleWord(event) {
    this.singleWord = new WordE();
    let ob = this.http.get<WordE>(this.global.server + '/word/' + event + "/" + this.blackServerId);
    ob.subscribe(details => {
      this.singleWord = details;
    }, err => {
      this.loadWord = false;
    }).add(() => {
      this.loadWord = true;
    });


  }

  loadLinkImgRoles(event) {

    this.linkC = [];
    this.imgC = [];

    this.loadLinkRole(event);
    this.loadImgRole(event);

  }

  loadLinkRole(event) {
    this.linkRole = new Settings();
    this.imgLinkServerId = event;
    let ob = this.http.get<Settings>(this.global.server + '/settings/linkrole/' + this.imgLinkServerId);
    ob.subscribe(details => {
      if (details == null) {
        this.linkRole = new Settings();
      } else {
        this.linkRole = details;
      }
    }, err => {
      console.log(this.channels);
    }).add(() => {
      this.loadLinkChannels(event);
    });
  }

  updateLinkRole() {
    let ob = this.http.post<Settings>(this.global.server + '/settings/update/', this.linkRole);
    ob.subscribe(details => {

    }, err => {
      console.log(err);
    }).add(() => {
      this.loadLinkRole(this.imgLinkServerId);
    });
  }

  loadImgRole(event) {
    this.imgRole = new Settings();
    this.imgLinkServerId = event;
    let ob = this.http.get<Settings>(this.global.server + '/settings/imagerole/' + this.imgLinkServerId);
    ob.subscribe(details => {
      if (details == null) {
        this.imgRole = new Settings();
      } else {
        this.imgRole = details;
      }
    }, err => {
      console.log(this.channels);
    }).add(() => {
      this.loadImgChannels(event);
    });
  }

  updateImgRole() {
    let ob = this.http.post<Settings>(this.global.server + '/settings/update/', this.imgRole);
    ob.subscribe(details => {

    }, err => {
      console.log(err);
    }).add(() => {
      this.loadImgRole(this.imgLinkServerId);
    });
  }

  loadImgChannels(event) {
    this.imgC = [];
    this.imgLinkServerId = event;
    let ob = this.http.get<Settings[]>(this.global.server + '/settings/list/imagechat/' + this.imgLinkServerId);
    ob.subscribe(details => {
      this.imgC = details;
    }, err => {
      console.log(this.channels);
    }).add(() => {
      
    });
  }

  loadLinkChannels(event) {
    this.linkC = [];
    this.imgLinkServerId = event;
    let ob = this.http.get<Settings[]>(this.global.server + '/settings/list/linkchat/' + this.imgLinkServerId);
    ob.subscribe(details => {
      this.linkC = details;
    }, err => {
      console.log(this.channels);
    }).add(() => {
      
    });
  }

  loadServerChannels(event) {
    let ob = this.http.get<Channel[]>(this.global.server + '/msg/channels/' + event);
    ob.subscribe(details => {
      this.channels = details;
    }, err => {
      console.log(this.channels);
    }).add(() => {
      this.loadCurrentChannels();
    });
    this.channelsServerId = event;
  }

  loadSyncRoles() {
    let ob = this.http.get<SyncRole[]>(this.global.server + '/sync/list');
    ob.subscribe(details => {
      this.syncRolesList = details;
    }, err => {
      console.log(this.channels);
    }).add(() => {
      for (var role of this.syncRolesList) {
        for (var serverD of this.serversDisplay) {
          if (role.server1 == serverD.server_id) {
            role.server1 = serverD.server_name;
            break;
          }
        }
        for (var serverD of this.serversDisplay) {
          if (role.server2 == serverD.server_id) {
            role.server2 = serverD.server_name;
            break;
          }
        }
      }
    });
  }

  loadCurrentChannels() {
    this.joinChannel = new Channel();
    let ob = this.http.get<Settings>(this.global.server + '/settings/join/' + this.channelsServerId);
    ob.subscribe(details => {
      if (details == null) {
        this.joinChannel = new Channel();
      } else {
        this.joinChannel.id = details.id;
        this.joinChannel.channelId = details.value;
        this.joinChannel.channelName = details.value2;
      }
    }, err => {
      console.log(this.channels);
    }).add(() => {
      
    });

    this.leaveChannel = new Channel();
    ob = this.http.get<Settings>(this.global.server + '/settings/leave/' + this.channelsServerId);
    ob.subscribe(details => {
      if (details == null) {
        this.leaveChannel = new Channel();
      } else {
        this.leaveChannel.id = details.id;
        this.leaveChannel.channelId = details.value;
        this.leaveChannel.channelName = details.value2;
      }
    }, err => {
      console.log(this.channels);
    }).add(() => {
      
    });

    this.generalChannel = new Channel();
    ob = this.http.get<Settings>(this.global.server + '/settings/general/' + this.channelsServerId);
    ob.subscribe(details => {
      if (details == null) {
        this.generalChannel = new Channel();
      } else {
        this.generalChannel.id = details.id;
        this.generalChannel.channelId = details.value;
        this.generalChannel.channelName = details.value2;
      }
    }, err => {
      console.log(this.channels);
    }).add(() => {
      
    });

    this.notChannel = new Channel();
    ob = this.http.get<Settings>(this.global.server + '/settings/not/' + this.channelsServerId);
    ob.subscribe(details => {
      if (details == null) {
        this.notChannel = new Channel();
      } else {
        this.notChannel.id = details.id;
        this.notChannel.channelId = details.value;
        this.notChannel.channelName = details.value2;
      }
    }, err => {
      console.log(this.channels);
    }).add(() => {
      
    });

    this.logsChannel = new Channel();
    ob = this.http.get<Settings>(this.global.server + '/settings/logs/' + this.channelsServerId);
    ob.subscribe(details => {
      if (details == null) {
        this.logsChannel = new Channel();
      } else {
        this.logsChannel.id = details.id;
        this.logsChannel.channelId = details.value;
        this.logsChannel.channelName = details.value2;
      }
    }, err => {
      console.log(this.channels);
    }).add(() => {
      
    });

    this.policeChannel = new Channel();
    ob = this.http.get<Settings>(this.global.server + '/settings/police/' + this.channelsServerId);
    ob.subscribe(details => {
      if (details == null) {
        this.policeChannel = new Channel();
      } else {
        this.policeChannel.id = details.id;
        this.policeChannel.channelId = details.value;
        this.policeChannel.channelName = details.value2;
      }
    }, err => {
      console.log(this.channels);
    }).add(() => {
      
    });
  }

  updateJoinChannel() {
    this.updateSetting = new Settings();

    this.updateSetting.id = this.joinChannel.id;
    this.updateSetting.value = this.joinChannel.channelId;
    this.updateSetting.value2 = this.joinChannel.channelName;

    let ob = this.http.post<Settings>(this.global.server + '/settings/update/', this.updateSetting);
    ob.subscribe(details => {
    }, err => {
      console.log(this.channels);
    }).add(() => {
      
    });
  }

  updateLeaveChannel() {
    this.updateSetting = new Settings();

    this.updateSetting.id = this.leaveChannel.id;
    this.updateSetting.value = this.leaveChannel.channelId;
    this.updateSetting.value2 = this.leaveChannel.channelName;

    let ob = this.http.post<Settings>(this.global.server + '/settings/update/', this.updateSetting);
    ob.subscribe(details => {
    }, err => {
      console.log(this.channels);
    }).add(() => {
      
    });
  }

  updateGeneralChannel() {
    this.updateSetting = new Settings();

    this.updateSetting.id = this.generalChannel.id;
    this.updateSetting.value = this.generalChannel.channelId;
    this.updateSetting.value2 = this.generalChannel.channelName;

    let ob = this.http.post<Settings>(this.global.server + '/settings/update/', this.updateSetting);
    ob.subscribe(details => {
    }, err => {
      console.log(this.channels);
    }).add(() => {
      
    });
  }

  updateNotiChannel() {

    this.updateSetting = new Settings();

    this.updateSetting.id = this.notChannel.id;
    this.updateSetting.value = this.notChannel.channelId;
    this.updateSetting.value2 = this.notChannel.channelName;

    let ob = this.http.post<Settings>(this.global.server + '/settings/update/', this.updateSetting);
    ob.subscribe(details => {
    }, err => {
      console.log(this.channels);
    }).add(() => {
      
    });
  }

  updatePoliceChannel() {
    this.updateSetting = new Settings();

    this.updateSetting.id = this.policeChannel.id;
    this.updateSetting.value = this.policeChannel.channelId;
    this.updateSetting.value2 = this.policeChannel.channelName;

    let ob = this.http.post<Settings>(this.global.server + '/settings/update/', this.updateSetting);
    ob.subscribe(details => {
    }, err => {
      console.log(this.channels);
    }).add(() => {
      
    });
  }

  updateLogsChannel() {

    this.updateSetting = new Settings();

    this.updateSetting.id = this.logsChannel.id;
    this.updateSetting.value = this.logsChannel.channelId;
    this.updateSetting.value2 = this.logsChannel.channelName;

    let ob = this.http.post<Settings>(this.global.server + '/settings/update/', this.updateSetting);
    ob.subscribe(details => {
    }, err => {
      console.log(this.channels);
    }).add(() => {
      
    });
  }

  deleteSetting(event) {

    this.updateSetting = new Settings();

    this.updateSetting.id = event;

    let ob = this.http.post<Settings>(this.global.server + '/settings/update/', this.updateSetting);
    ob.subscribe(details => {
    }, err => {
      console.log(this.channels);
    }).add(() => {
      
    });
  }

  sendBotMsg() {

    if (this.sendGeneral) {
      this.botMsg.channelId = this.generalChannel.channelId;
      this.botMsg.serverId = this.ahrzaServerId;
      this.botMsg.text = this.ahrzaText.text;
  
      let ob = this.http.post<BotMsg>(this.global.server + '/botmsg', this.botMsg);
      ob.subscribe(details => {
      }, err => {
        console.log("Error Send BotMsg");
      }).add(() => {
        this.openSuccessAhrza();
      });
    }

    if (this.sendNot) {
      this.botMsg.channelId = this.notChannel.channelId;
      this.botMsg.serverId = this.ahrzaServerId;
      this.botMsg.text = this.ahrzaText.text;
  
      let ob = this.http.post<BotMsg>(this.global.server + '/botmsg', this.botMsg);
      ob.subscribe(details => {
      }, err => {
        console.log("Error Send BotMsg");
      }).add(() => {
        this.openSuccessAhrza();
      });
    }
    this.closeAhrza();

  }

  activeServer(event, event2) {
    let style = {};

    if(event == event2) {
      style = {
        'border' : '8px inset rgb(171, 171, 255)',
        'opacity': '1'
      }
    } else {
      style = {
        'opacity': '.3'
      }
    }
    return style;
  }

}

function delay(ms: number) {
  return new Promise( resolve => setTimeout(resolve, ms) );
}