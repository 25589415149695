export class LevelEntry {

  constructor(
    public userName?: string,
    public userId?: string,
    public level?: string,
    public exp?: string,
    public need_exp?: string,
    public points?: string,
  ) {  }

}