import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Msg } from '../chat/_Models/msg/msg';
import { Glds } from '../_Models/glds';
import { Logs } from '../_Models/Logs';
import { Server } from '../_Models/server';

@Component({
  selector: 'app-logs-deletedmessages',
  templateUrl: './logs-deletedmessages.component.html',
  styleUrls: ['./logs-deletedmessages.component.css']
})
export class LogsDeletedmessagesComponent implements OnInit {

  constructor(private httpClient: HttpClient) { }


  logs: Logs[] = [];
  global: Glds = new Glds;
  public serverlist = new Server();
  serverId: string;
  messages: Msg[] = [];

  ngOnInit() {
    this.loadServerList();
  }

  loadServerList() {
    let ob = this.httpClient.get<Server>(this.global.server + '/msg/serverlist');
    ob.subscribe(details => {
      this.serverId = details[0].serverId;
      this.serverlist = details;
    }, err => {
      console.log(this.serverlist);
    }).add(() => {
      this.handleLogsList(this.serverId);
    });

    console.log(this.serverlist);

  }

  handleLogsList(event) {
    this.serverId = event;
    let ob = this.httpClient.get<Msg[]>(this.global.server + '/msg/server/deleted/' + event);
    ob.subscribe(logs => {
      this.messages = logs;
    }, err => {
      this.handleLogsList(this.serverId);
    }).add(() => {
      // this.buildBox();
    });
    
  }

  columnColor(event: String): String{
    if(event == 'SECURITY_LOGIN') {
      return "#DC3545";
    }
    if(event == 'ERROR_SQL') {
      return '#006EC0';
    }
    if(event == 'INFO_UPDATE' || event == 'INFO_CREATE') {
      return '#006EC0';
    }
  }

  activeServer(event) {
    let style = {};

    if(event == this.serverId) {
      style = {
        'border' : '8px inset rgb(171, 171, 255)',
        'opacity': '1'
      }
    } else {
      style = {
        'opacity': '.3'
      }
    }
    return style;
  }

}
