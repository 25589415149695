export class Channel {

    constructor(
      
        public id?: number,
        public channelId?: string,
        public channelName?: string,
      ) {  }

}
