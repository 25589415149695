import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Glds } from '../_Models/glds';
import { Logs } from '../_Models/Logs';

@Component({
  selector: 'app-users-log',
  templateUrl: './users-log.component.html',
  styleUrls: ['./users-log.component.css']
})
export class UsersLogComponent implements OnInit {

  constructor(private httpClient: HttpClient, private route: ActivatedRoute) { }

  logs: Logs[] = [];
  global: Glds = new Glds;

  userId: String;

  ngOnInit(): void {
    this.userId = null;
    this.route.params.subscribe(params => {
      this.userId = params['id'];
      this.handleLogsList();
    });
    this.handleLogsList();
  }

  handleLogsList() {
    let ob = this.httpClient.get<Logs[]>(this.global.server + '/logs/user/' + this.userId);
    ob.subscribe(logs => {
      console.log(logs);
      this.logs = logs;
    }, err => {

    }).add(() => {
      // this.buildBox();
    });
    
  }

  columnColor(event: String): String{
    if(event == 'SECURITY_LOGIN') {
      return "#DC3545";
    }
    if(event == 'ERROR_SQL') {
      return '#006EC0';
    }
    if(event == 'INFO_UPDATE' || event == 'INFO_CREATE') {
      return '#006EC0';
    }
  }
}
