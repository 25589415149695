export class Shop {

  constructor(
    public id?: number,
    public productId?: string,
    public title?: string,
    public description?: string,
    public mode?: string,
    public quality?: number,
    public pail?: number,
    public value1?: string,
    public value2?: string,
  ) {  }

}