export class BotReact {

  constructor(
    public id?: number,
    public serverId?: string,
    public channelId?: string,
    public messageId?: string,
    public reaction?: string,
    public pail?: number,
    public value1?: string,
  ) {  }

}