import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { Glds } from '../_Models/glds';
import { Shop } from '../_Models/Shop';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.css']
})

export class ShopComponent implements OnInit {

  curShop: Shop = new Shop();

  newShop: Shop = new Shop();

  shopList: Shop[] = [];

  global: Glds = new Glds;

  new: boolean;

  constructor(private httpClient: HttpClient) { }

  ngOnInit() {
    this.loadShop();
  }

  openNewShopSystem(event) {
    this.newShop = new Shop;

    if (event == null) {
      this.new = true;
    $('.NewShopModal').css("visibility", "visible") + " !important";
    } else {
      this.new = false;
      this.loadSingleShop(event);
      $('.NewShopModal').css("visibility", "visible") + " !important";
    }
  }

  closeNewShopSystem() {
    $('.NewShopModal').css("visibility", "hidden") + " !important";
  }

  createShop() {
    this.newShop.mode = "NORMAL"
    let ob = this.httpClient.post<Shop>(this.global.server + '/shop', this.newShop);
    ob.subscribe(details => {
    }, err => {
      console.log(err);
    }).add(() => {
      this.closeNewShopSystem();
      this.loadShop();
    });

  }

  updateShop() {
    let ob = this.httpClient.post<Shop>(this.global.server + '/shop/update', this.newShop);
    ob.subscribe(details => {
      console.log(this.newShop);
    }, err => {
      console.log(err);
    }).add(() => {
      this.closeNewShopSystem();
      this.loadShop();
    });

  }

  loadShop() {
    let ob = this.httpClient.get<Shop[]>(this.global.server + '/shop');
    ob.subscribe(details => {
      this.shopList = details;
    }, err => {
      console.log(err);
    }).add(() => {
      
    });

  }

  loadSingleShop(event) {
    let ob = this.httpClient.get<Shop>(this.global.server + '/shop/' + event);
    ob.subscribe(details => {
      this.newShop = details;
    }, err => {
      console.log(err);
    }).add(() => {
    });

  }

}
