export class Glds {

    ip?: String = "privatebot.co.il";
    // ip?: String = "45.88.189.76";
    // ip?: String = "188.114.96.3";
    // ip?: String = "localhost";
    port?: String = "8443";

    server?: String = "https://" + this.ip +":" + this.port;

    ipLogin?: String = "144.91.84.218";
    // ip?: String = "localhost";
    portLogin?: String = "8090";

    // serverLogin?: String = "http://" + this.ipLogin +":" + this.portLogin;
    serverLogin?: String = "https://" + this.ip +":" + this.port;

    domain?: string = "privatebot.co.il"

}
