import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { HttpClientModule} from '@angular/common/http';

import { Routes, RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { ChatComponent } from './chat/chat.component';
import { TextComponent } from './chat/text/text.component';
import { LoginComponent } from './login/login.component';
import { PanelComponent } from './components/panel/panel.component';

import { FormsModule } from '@angular/forms';
import { AdminGuardService } from './_Guards/AdminGuardService';

import { CookieService } from 'ngx-cookie-service';

import { ReactiveFormsModule } from '@angular/forms';
import { LogsComponent } from './logs/logs.component';
import { ActionComponent } from './action/action.component';
import { LogsDevComponent } from './logs-dev/logs-dev.component';
import { LogsDeletedmessagesComponent } from './logs-deletedmessages/logs-deletedmessages.component';
import { LogsKickbansComponent } from './logs-kickbans/logs-kickbans.component';
import { UsersComponent } from './users/users.component';
import { UserMessagesComponent } from './user-messages/user-messages.component';
import { ShopComponent } from './shop/shop.component';
import { UsersLogComponent } from './users-log/users-log.component';
import { HomeComponent } from './home/home.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { GamesComponent } from './games/games.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {path: 'login', component: LoginComponent},
  {path: 'panel', canActivate: [AdminGuardService], component: PanelComponent, children : [
    {path: '', component: HomeComponent},
    { path: 'chat', component: ChatComponent , children : [
      {path: ':serverId', component: TextComponent},
      {path: ':serverId/:channelId', component: TextComponent}
    ]},
    {path: 'logs', component: LogsComponent},
    {path: 'users', component: UsersComponent},
    {path: 'users/logs/:id', component: UsersLogComponent},
    {path: 'logs/dev', component: LogsDevComponent},
    {path: 'logs/deletedmessages', component: LogsDeletedmessagesComponent},
    {path: 'logs/kick-bans', component: LogsKickbansComponent},
    {path: 'action', component: ActionComponent},
    {path: 'shop', component: ShopComponent},
    {path: 'shop/:id', component: ProductDetailsComponent},
    {path: 'user/messages/:userId', component: UserMessagesComponent},
    {path: 'games', component: GamesComponent},
  ]},
];

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ChatComponent,
    TextComponent,
    LoginComponent,
    PanelComponent,
    LogsComponent,
    ActionComponent,
    LogsDevComponent,
    LogsDeletedmessagesComponent,
    LogsKickbansComponent,
    UsersComponent,
    UserMessagesComponent,
    ShopComponent,
    UsersLogComponent,
    HomeComponent,
    ProductDetailsComponent,
    GamesComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    [RouterModule.forRoot(routes)]
  ],
  providers: [CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }
