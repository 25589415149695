export class Msg {

  constructor(
    public id?: number,
    public avatar?: string,
    public bot?: boolean,
    public text?: string,
    public date?: string,
    public messageId?: number,
    public serverId?: number,
    public serverName?: string,
    public channelId?: number,
    public channelName?: string,
    public userId?: number,
    public userName?: string,
    public filtredText?: string,
  ) {  }

}