export class UserDisplay {

  constructor(
    public user_name?: string,
    public user_id?: string,
    public user_avatar?: string,
    public user_exp?: string,
    public user_needexp?: string,
    public message_cnt?: string,
    public user_points?: string,
    public user_warnings?: string,
    public user_kicks?: string,
    public user_bans?: string,
    public user_level?: string,
  ) {  }

}