export class Settings {

  constructor(
    public id?: number,
    public setting?: string,
    public desc?: string,
    public value?: string,
    public value2?: string,
    public serverId?: number,
  ) {  }

}