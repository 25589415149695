import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Glds } from '../_Models/glds';
import { Logs } from '../_Models/Logs';

@Component({
  selector: 'app-logs-kickbans',
  templateUrl: './logs-kickbans.component.html',
  styleUrls: ['./logs-kickbans.component.css']
})
export class LogsKickbansComponent implements OnInit {

  constructor(private httpClient: HttpClient) { }

  logs: Logs[] = [];
  global: Glds = new Glds;

  ngOnInit(): void {
    this.handleLogsList();
  }

  handleLogsList() {
    let ob = this.httpClient.get<Logs[]>(this.global.server + '/logs/warning');
    ob.subscribe(logs => {
      this.logs = logs;
    }, err => {
      this.handleLogsList();
    }).add(() => {
      // this.buildBox();
    });
    
  }

  columnColor(event: String): String{
    if(event == 'SECURITY_LOGIN') {
      return "#DC3545";
    }
    if(event == 'ERROR_SQL') {
      return '#006EC0';
    }
    if(event == 'INFO_UPDATE' || event == 'INFO_CREATE') {
      return '#006EC0';
    }
  }

}
