export class User {

  constructor(
    public id?: number,
    public username?: string,
    public password?: string,
    public token?: string,
    public role?:string,
    public twoauth?: boolean,
    public twocode?: string,
    public ipa?: string,
    public usertag?: string,
    public useremail?: string,
    public userphone?: string,
    public firstname?: string,
    public domain?: string,
    public sugcode?: number
  ) {  }

}